
import { defineComponent } from "vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "DocxDone",
  components: {
    FlatButton,
  },
  setup() {
    const router = useRouter();

    const returnTemplateList = () => {
      router.push({ name: "TemplateList" });
    };

    return { returnTemplateList };
  },
});
